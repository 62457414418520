<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add :icon="'far fa-plus-square'" :title="'Создать уведомление'" v-if="getAccess('eventCreate')" @click="createNew()" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)"> </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  mixins: [getAccess],
  components: {
    editDialog: () => import("./dialogs/eventDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.events.list,
      url: "/mechti/events",
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "events",
      },
      balance: {
        debit: 0,
        credit: 0,
      },
    };
  },
  created() {
    this.$root.title = "Уведомления";
  },
  computed: {
    permit() {
      return this.getAccess("menu.events");
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
